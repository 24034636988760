@import 'slick-carousel/slick/slick-theme.css';
@import 'slick-carousel/slick/slick.css';
@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify__toast {
  @apply label-base;
}

@layer base {
  :root {
    --toastify-font-family: 'Proxima Nova', sans-serif;
    --toastify-color-dark: rgba(0, 0, 0, 0.95);
    --toastify-color-info: theme('colors.white');
    --toastify-color-success: theme('colors.primary');
    --toastify-color-warning: theme('colors.secondary');
    --toastify-color-error: theme('colors.gray.300');
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: theme(colors.primary);
    caret-color: theme(colors.primary);
    transition: background-color 5000s ease-in-out 5000s;
  }

  /** Fixes 1password autofill bg */
  input[data-com-onepassword-filled] {
    background-clip: text;
  }

  select {
    @apply font-medium text-gray-500;
  }

  .input-tvm-classic {
    @apply inline-block min-h-[43px] min-w-0 items-center self-start rounded-full border-none bg-gray-700 px-5 py-2 pr-10 text-left text-base focus:ring-secondary;
  }

  .os-scrollbar {
    --os-padding-axis: 1.25rem;
    margin-right: 1rem;
    --os-size: 0.25rem;
    --os-padding-perpendicular: 0;
    --os-track-border-radius: 0.5rem;
    --os-track-bg: theme(colors.black);
    --os-track-bg-hover: theme(colors.black);
    --os-track-bg-active: theme(colors.black);
    --os-track-border: none;
    --os-track-border-hover: none;
    --os-track-border-active: none;
    --os-handle-border-radius: 0.5rem;
    --os-handle-bg: theme(colors.gray.500);
    --os-handle-bg-hover: theme(colors.gray.500);
    --os-handle-bg-active: theme(colors.gray.500);
    --os-handle-border: none;
    --os-handle-border-hover: none;
    --os-handle-border-active: none;
    --os-handle-min-size: 33px;
    --os-handle-max-size: none;
    --os-handle-perpendicular-size: 100%;
    --os-handle-perpendicular-size-hover: 100%;
    --os-handle-perpendicular-size-active: 100%;
    --os-handle-interactive-area-offset: 0;
  }

  .os-scrollbar-main {
    --os-padding-axis: 1.25rem;
    --os-size: 0.4rem;
    --os-padding-perpendicular: 0;
    --os-track-border-radius: 0.5rem;
    --os-track-bg: none;
    --os-track-bg-hover: none;
    --os-track-bg-active: none;
    --os-track-border: none;
    --os-track-border-hover: none;
    --os-track-border-active: none;
    --os-handle-border-radius: 0.5rem;
    --os-handle-bg: theme(colors.gray.500);
    --os-handle-bg-hover: theme(colors.primary);
    --os-handle-bg-active: theme(colors.primary);
    --os-handle-border: none;
    --os-handle-border-hover: none;
    --os-handle-border-active: none;
    --os-handle-min-size: 33px;
    --os-handle-max-size: none;
    --os-handle-perpendicular-size: 70%;
    --os-handle-perpendicular-size-hover: 100%;
    --os-handle-perpendicular-size-active: 100%;
    --os-handle-interactive-area-offset: 100px;
    @apply mr-[3px] md:mr-[7px];
  }
}

@layer components {
  .hyphenate {
    overflow-wrap: break-all;
    word-wrap: break-all;
    hyphens: auto;
  }

  /* input[type='text'],
  select {
    @apply inline-block min-h-[43px] min-w-0 items-center self-start rounded-full border-none bg-gray-700 px-5 py-2 pr-10 text-left text-base focus:ring-secondary;
  } */

  input[type='text'].giant,
  select.giant {
    @apply inline-block w-auto min-w-0 rounded-full border-none bg-gray-700 px-8 py-3 text-center text-2xl font-semibold;
  }

  input[type='search'].extra-giant {
    @apply block h-20 w-full border-none bg-transparent px-0 py-0 text-left font-herokid text-4xl font-bold leading-header text-white outline-none placeholder:text-gray-600 focus:outline-none focus:ring-0 sm:text-5xl md:text-6xl;
  }

  select.giant {
    @apply pr-16;
  }

  .tvm-scrollbar {
    @apply scrollbar-thin scrollbar-track-white scrollbar-thumb-primary scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
  }
  .tvm-scrollbar-dark {
    @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-200 scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
  }

  .radial-carousel-switcher-background {
    background: radial-gradient(ellipse, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 60%);
  }
  .streaming-entry-bg {
    @apply bg-gradient-to-b from-gray-650/20 via-gray-500/25 to-gray-650/20;
  }

  .content-element-padding {
    @apply md:px-4 lg:px-16 xl:px-32;
  }

  .heading-1 {
    @apply font-herokid text-heading-mobile-1 md:text-heading-1;
  }

  .heading-2 {
    @apply font-herokid text-heading-mobile-2 md:text-heading-2;
  }

  .heading-3 {
    @apply font-herokid text-heading-mobile-3 md:text-heading-3;
  }

  .heading-4 {
    @apply font-herokid text-heading-mobile-4 md:text-heading-4;
  }

  .heading-5 {
    @apply font-herokid text-heading-mobile-5 md:text-heading-5;
  }

  .heading-6 {
    @apply font-herokid text-heading-mobile-6 md:text-heading-6;
  }

  .heading-7 {
    @apply font-herokid text-heading-7;
  }

  .label-lg {
    @apply font-proxima-nova text-label-lg uppercase;
  }

  .label-base {
    @apply font-proxima-nova text-label-base uppercase;
  }

  .label-sm {
    @apply font-proxima-nova text-label-sm uppercase;
  }

  .link {
    @apply underline decoration-[0.5px] underline-offset-3;
  }
}

body {
  @apply font-proxima-nova leading-body;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

.button-icon {
  @apply card-gradient flex size-[38px] items-center justify-center rounded-full border border-gray-650 text-white transition-colors duration-300 group-hover:border-primary group-hover:text-primary hover:border-primary hover:text-primary;
}

.content-element-padding {
  @apply md:px-4 xl:px-32;
}
.card-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #1c1c1e;
}

.abo-card-gradient {
  background: linear-gradient(101deg, #1b1b1b 0%, #1c1c1e 100%);
}

#main-search-input::-webkit-search-cancel-button {
  display: none;
}

@layer utilities {
  .checkmark-list ul {
    @apply space-y-2.5 pl-6 *:list-disc *:list-image-[url(/assets/icons/icon-checkmark.svg)];
  }

  .divider-container {
    @apply flex flex-wrap justify-start;
    margin-left: -10px; /* half of column gap */
  }

  .divider-item {
    position: relative;
    margin-left: 10px; /* half of column gap */
    margin-right: 10px; /* half of column gap */
    &::after {
      @apply absolute right-full top-1/2 inline-block h-12 w-0.5 -translate-y-1/2 bg-gray-650/65;
      content: '';
      margin-right: 11px; /* (column gap - divider) / 2 + border */
    }
  }

  .content-auto {
    content-visibility: auto;
  }
  .content-visible {
    content-visibility: visible;
  }

  .channel-bg {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #1c1c1e;
  }
}

/* Shorts styling (custom video.js skin) - just pass vjs-shorts-skin to the component to use this skin */
.vjs-shorts-skin .vjs-control-bar {
  background-color: transparent !important;
  height: 6px !important;
}

.vjs-shorts-skin .vjs-slider {
  background-color: transparent !important;
  margin: 0 !important;
  height: 6px !important;
}

.vjs-shorts-skin .vjs-slider-bar:before {
  font-size: 8px !important;
  top: 50% !important;
  color: #ff0000 !important;
  line-height: 0 !important;
}

.vjs-shorts-skin .vjs-progress-control {
  display: flex !important;
}

.vjs-shorts-skin .vjs-progress-control:hover .vjs-slider-bar:before {
  font-size: 18px !important;
  z-index: 0 !important;
}

.vjs-shorts-skin .vjs-play-progress {
  background: #ff0000 !important;
}

.vjs-shorts-skin .vjs-load-progress {
  background: transparent !important;
}

.vjs-shorts-skin .vjs-load-progress div {
  background: transparent !important;
}
